import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Icon from "../custom-widgets/icon";
import VideoModal from "../video-modal/video-modal";
import Button from "../custom-widgets/button";
import styles from "./custom-large-cards.module.scss";

/**
 * @typedef {Object} ButtonProps
 * @property {string} id - Required string for the button.
 * @property {string} url - Optional string for the button.
 */

/**
 * @typedef {Object} CardData
 * @property {string} title - Required string green text title of the card.
 * @property {string} subtitle - Optional string to show a subtitle at the bottom of the card.
 * @property {string} disclaimer - Optional string to show a disclaimer at the bottom of the card.
 * @property {ButtonProps} mainButton - [Add how this button is different to the secondary button]
 * @property {ButtonProps} secondaryButton - optional object with the attributes for the secondary button, this is shown as a link text button.
 * @property {Object[]} contentListGreenCircleChecked - Optional array of objects with just "content" as attribute that requires a react component to render.
 * @property {Object} videoData - Optional object that will show a video modal when the main button is clicked.
 * @property {string} videoData.VimeoID - Required string with the Vimeo ID of the video.
 */

/**
 * This is the component to render the custom large cards in a row, it can handle just 2 cards for now.
 *
 * @param {Object} props - The object containing props.
 * @param {Object} props.imgData - Required object with the image data for each of the 2 cards.
 *   - `largeCard1`: required Gatsby image data.
 *   - `altTextCard1`: required string with the alt text for the card image.
 *   - `largeCard2`: required Gatsby image data.
 *   - `altTextCard2`: required string with the alt text for the card image.
 * @param {CardData[]} props.cardsData - Required Array of objects with the data and content for the card.
 *   - `title`: required string green text title of the card.
 *   - `subtitle`: Optional string to show a subtitle at the bottom of the card.
 *   - `disclaimer`: optional string to show a disclaimer at the bottom of the card.
 *   - `mainButton`: required object with attributes required "title", optional "url" for the button.
 *   - `secondaryButton`: optional object with the attributes for the secondary button, this is shown as a link text button.
 *   - `contentListGreenCircleChecked`: optional array of objects with just "content" as attribute that requires a react component to render.
 *   - `videoData`: optional object with "VimeoID" as attribute that requires the string ID of the video we want to show if needed.
 * @param {bool} props.noBackground - If false or undefined we use "bg-info pt-0 pb-3" for the section classes.
 * @param {string} props.sectionClass - If noBackground is true we use it like this "container ${sectionClass}" for the section classes.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const CustomLargeCards = ({ imgData: propImgData, cardsData: propCardsData, noBackground, sectionClass }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);
  const handleCloseVideo = () => {
    setVideoSelected(null);
    setShowVideo(false);
  };
  const handleShowVideo = (videoProps, cardTitle) => {
    setVideoSelected({ ...videoProps, Title: cardTitle });
    setShowVideo(true);
  };

  const defaultImgData = useStaticQuery(graphql`
    {
      largeCard1: file(relativePath: { eq: "cards/thumbnail-money-market-110723.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-greenlight-110723.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  const imgData = propImgData ? propImgData : defaultImgData;

  const defaultCardsData = [
    {
      title: "Save More With a High Yield Money Market",
      subtitle: "Build wealth with confidence and peace of mind",
      mainButton: {
        id: "homepage-high-yield-money-market-cta",
        url: "/personal-banking/savings-account",
        title: "Get started today"
      },
      secondaryButton: {
        id: "homepage-interest-rates-link",
        url: "/personal-banking/interest-rates",
        title: "See today's rates"
      }
    },
    {
      title: "Raise financially savvy kids with Greenlight",
      subtitle: "Fun games, their own debit card, and parental controls courtesy of WaFd",
      mainButton: {
        id: "homepage-greenlight-cta",
        url: "/personal-banking/greenlight-kids-debit-card",
        title: "Learn More"
      },
      secondaryButton: {
        id: "homepage-interest-rates-link",
        url: "/personal-banking/interest-rates",
        title: "See today's rates"
      }
    }
  ];

  const cardsData = propCardsData ? propCardsData : defaultCardsData;

  //TODO sometime, this can be an array of big cards depending on how many images have the imgData const
  return (
    <section className={noBackground ? `container ${sectionClass}` : "bg-info pt-0 pb-3"}>
      <div className="container">
        <div id="homepage-large-cards" className="row large-cards">
          {cardsData.map((card, idx) => {
            return (
              <div
                key={idx}
                className={
                  "col-md-6 pb-3 pb-md-0 mb-3 mb-md-0 " +
                  (noBackground
                    ? idx === cardsData.length - 1
                      ? styles.paddingRightCardContainer
                      : styles.paddingLeftCardContainer
                    : "")
                }
              >
                <div
                  id={"large-card-" + (idx + 1)}
                  className={
                    "card border-radius-12 h-100 position-relative border-0 " + (noBackground ? "card-box-shadow" : "")
                  }
                >
                  <GatsbyImage
                    className="border-radius-12 border-radius-bottom-0"
                    image={
                      idx === 0
                        ? imgData.largeCard1.childImageSharp.gatsbyImageData
                        : imgData.largeCard2.childImageSharp.gatsbyImageData
                    }
                    alt={idx === 0 ? imgData.altTextCard1 : imgData.altTextCard2}
                  />
                  <div className="card-body">
                    <h3 className="text-success font-weight-semi-bold">{card.title}</h3>
                    <p>{card.subtitle}</p>
                    <>{card.customSubtitle}</>
                    {card.contentListGreenCircleChecked?.map((listItem, idx) => (
                      <div className="d-flex align-items-start" key={idx}>
                        <Icon class="text-success mr-2 mt-1" lib="fas" name="check-circle" />
                        <div>
                          {listItem.title && <p className="text-success font-weight-bold mb-1">{listItem.title}</p>}
                          <>{listItem.content}</>
                        </div>
                      </div>
                    ))}
                    <div className="row mt-4 mx-0">
                      {card.secondaryButton &&
                        (card.videoData && card.mainButton.url ? (
                          <>
                            <Button
                              id={"large-card-" + (idx + 1) + "-secondary-cta"}
                              class={`d-none d-lg-block mr-md-4 mb-3 ${card.secondaryButton.class}`}
                              type="button"
                              showIcon={true}
                              icon={card.secondaryButton.icon}
                              text={card.secondaryButton.title}
                              onClick={() => {
                                handleShowVideo(card.videoData, card.title);
                              }}
                              externalLink={card.secondaryButton.externalLink}
                              target={card.secondaryButton.target}
                            />
                            <Button
                              id={"large-card-" + (idx + 1) + "-secondary-cta-mobile"}
                              class={`d-block d-lg-none mb-3 ${card.secondaryButton.class}`}
                              containerClass="w-100"
                              type="button"
                              showIcon={true}
                              icon={card.secondaryButton.icon}
                              text={card.secondaryButton.title}
                              onClick={() => {
                                handleShowVideo(card.videoData, card.title);
                              }}
                              externalLink={card.secondaryButton.externalLink}
                              target={card.secondaryButton.target}
                            />
                          </>
                        ) : (
                          <div className="pt-2 pb-3 pb-lg-0 mr-md-4">
                            <Link
                              id={"large-card-" + (idx + 1) + "-link"}
                              className="position-relative text-decoration-none"
                              to={card.secondaryButton.url}
                            >
                              {card.secondaryButton.title}
                              <Icon name="arrow-right" class="ml-1" />
                            </Link>
                          </div>
                        ))}
                      {card.mainButton && (
                        <>
                          <Button
                            id={"large-card-" + (idx + 1) + "-cta"}
                            class="d-none d-lg-block btn btn-primary no-min-width"
                            type={card.videoData && !card.mainButton.url ? "button" : "link"}
                            url={card.mainButton.url}
                            showIcon={!!card.mainButton.showIcon}
                            text={card.mainButton.title}
                            onClick={() => {
                              if (card.videoData && !card.mainButton.url) handleShowVideo(card.videoData, card.title);
                            }}
                            externalLink={card.mainButton.externalLink}
                            target={card.mainButton.target}
                            icon={card.mainButton.icon}
                          />
                          <Button
                            id={"large-card-" + (idx + 1) + "-cta-mobile"}
                            class="d-block d-lg-none btn btn-primary w-100"
                            containerClass="w-100"
                            type={card.videoData && !card.mainButton.url ? "button" : "link"}
                            url={card.mainButton.url}
                            showIcon={!!card.mainButton.showIcon}
                            text={card.mainButton.title}
                            onClick={() => {
                              if (card.videoData && !card.mainButton.url) handleShowVideo(card.videoData, card.title);
                            }}
                            externalLink={card.mainButton.externalLink}
                            target={card.mainButton.target}
                            icon={card.mainButton.icon}
                          />
                        </>
                      )}
                    </div>
                    {card.disclaimer && (
                      <p id={"large-card-" + (idx + 1) + "-disclaimer"} className="text-sm text-gray-60 mt-4 mb-0">
                        {card.disclaimer}
                      </p>
                    )}
                  </div>
                  {/* <div className="card-footer bg-white border-0 border-radius-bottom-12"></div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <VideoModal show={showVideo} handleClose={handleCloseVideo} {...videoSelected} />
    </section>
  );
};

export default CustomLargeCards;

CustomLargeCards.propTypes = {
  cardsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      disclaimer: PropTypes.string,
      mainButton: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string
      }).isRequired,
      contentListGreenCircleChecked: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.node.isRequired
        })
      ),
      videoData: PropTypes.shape({
        VimeoID: PropTypes.string.isRequired
      })
    })
  ).isRequired,
  imgData: PropTypes.shape({
    largeCard1: PropTypes.object.isRequired,
    altTextCard1: PropTypes.string.isRequired,
    largeCard2: PropTypes.object.isRequired,
    altTextCard2: PropTypes.string.isRequired
  }).isRequired,
  noBackground: PropTypes.bool,
  sectionClass: PropTypes.string
};
