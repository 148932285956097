import React from "react";
import { useLanguageContext } from "../../contexts/language-context";
import { StaticImage } from "gatsby-plugin-image";
import TwoColumnSectionWithImage from "../custom-widgets/two-column-section-with-image";
import List from "../custom-widgets/list";

const NewUIWafdBankBenefits = ({ isGray = true }) => {
  const isSpanish = useLanguageContext();

  const ID_TITLE = "benefits-item";
  const mortgageBenefitsDataList = {
    accountName: ID_TITLE,
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: `
          <p id=${ID_TITLE + "-1-title"} class="text-success font-weight-bold mb-1">
            We're Here for You
          </p>
          <p id=${ID_TITLE + "-1-description"}>
            From submitting your application through closing and beyond
          </p>
        `
      },
      {
        id: 2,
        text: `
          <p id=${ID_TITLE + "-2-title"} class="text-success font-weight-bold mb-1">
            Competitive Rates
          </p>
          <p id=${ID_TITLE + "-2-description"}>
            We have competitive mortgage rates and will be with you every step
          </p>
        `
      },
      {
        id: 3,
        text: `
          <p id=${ID_TITLE + "-3-title"} class="text-success font-weight-bold mb-1">
            Apply for a Loan Online
          </p>
          <p id=${ID_TITLE + "-3-description"}>
            You can quickly and securely apply online in minutes
          </p>
        `
      }
    ]
  };

  const mortgageBenefitsDataListSpanish = {
    accountName: ID_TITLE,
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: `
          <p id=${ID_TITLE + "-1-title"} class="text-success font-weight-bold mb-1">
            Estamos aquí para tí
          </p>
          <p id=${ID_TITLE + "-1-description"}>
            Desde el proceso de solicitud hasta cerrar el trato y más allá
          </p>
        `
      },
      {
        id: 2,
        text: `
          <p id=${ID_TITLE + "-2-title"} class="text-success font-weight-bold mb-1">
            Tasas competitivas
          </p>
          <p id=${ID_TITLE + "-2-description"}>
            Tenemos tasas hipotecarias competitivas y te acompañarémos en cada paso del camino
          </p>
        `
      },
      {
        id: 3,
        text: `
          <p id=${ID_TITLE + "-3-title"} class="text-success font-weight-bold mb-1">
            Solicita tu préstamo en línea
          </p>
          <p id=${ID_TITLE + "-3-description"}>
            Puedes aplicar de manera rápida y segura en línea en cuestión de minutos
          </p>
        `
      }
    ]
  };

  const EnglishContent = () => {
    return (
      <>
        <h3 className="d-none d-lg-block">Benefits of Working With WaFd Bank</h3>
        <h3 className="d-block d-lg-none mt-2">Benefits of Working With WaFd Bank</h3>
        <List {...mortgageBenefitsDataList} />
      </>
    );
  };

  const SpanishContent = () => {
    return (
      <>
        <h3 className="d-none d-lg-block">Beneficios de trabajar con WaFd Bank</h3>
        <h3 className="d-block d-lg-none mt-2">Beneficios de trabajar con WaFd Bank</h3>
        <List {...mortgageBenefitsDataListSpanish} />
      </>
    );
  };

  return (
    <>
      <TwoColumnSectionWithImage id="benefits-of-working-with-wafd-bank-section" contentOnLeft={false} isGray={isGray}>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              className="border-radius-12"
              src="../../images/thumbnail-what-is-a-portfolio-loan-100924.jpg"
              alt={
                isSpanish
                  ? "Oficial de préstamos explica los detalles del préstamo a una pareja en la oficina."
                  : "Loan officer explains loan details to couple in office."
              }
            />
          ),
          content: isSpanish ? <SpanishContent /> : <EnglishContent />
        }}
      </TwoColumnSectionWithImage>
    </>
  );
};

export default NewUIWafdBankBenefits;
